@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.prose a {
  text-decoration: none;
  color: white !important;
}

.home-name {
  font-size: 150px;
}

.my-primary-background {
  background-color: #427979;
}

.my-primary-border {
  border-color: #427979;
}

.wo-text-decoration {
  text-decoration: none;
}
